.account-box {
  width: 21.875rem;
  height: 13.125rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  background: #ffffff;
}

.hidden {
  visibility: hidden;
}

.login-box-wrapper {
  height: 100%;
  padding: 0 2.813rem;
  .loader {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .login-box {
    padding-top: 1.375rem;
  }
}

.login-text-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login-text {
  font-weight: 700;
  font-size: 1.563rem;
  color: #171e4f;
  line-height: 1.8;
}

.login-fail-text {
  font-weight: 400;
  font-size: 0.813rem;
  color: #ff0000;
  line-height: 1.8;
  font-style: italic;
}

.login-form {
  display: flex;
}

.username-password {
  display: flex;
  flex-direction: column;
  input {
    width: 10.625rem;
    height: 2.5rem;
    box-sizing: border-box;
    background: #f9f9f9;
    border: 0.5px solid #b8b8b8;
  }
}

.login-button {
  width: 5.688rem;
  height: 5rem;
  background: #171e4f;
  color: #ffffff;
  font-weight: 700;
  line-height: 1.8;
  border: 0;
  &:hover {
    cursor: pointer;
    background-color: #303c9b;
  }
}

.login-others {
  margin-top: 0.625rem;
  display: flex;
  font-size: 0.625rem;
  justify-content: space-between;
  span {
    &:hover {
      cursor: pointer;
      color: #9d9a9a;
    }
  }
  .register {
    text-decoration: none;
    font-weight: 700;
    color: #171e4f;
    &:hover {
      cursor: pointer;
      color: #303c9b;
    }
  }
}
