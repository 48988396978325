.form-item {
  display: flex;
  flex-direction: row;
  gap: 2.375rem;
}

.form-item-title {
  width: 6.875rem;
  padding-top: 0.75rem;
  color: #3a3a3a;
}

#re-enter {
  padding: 0;
  display: flex;
  align-items: center;
}

.form-item-content {
  flex: 1;
  input {
    width: 100%;
    min-width: 140px;
    background: #f8f8f8;
    border: 1px solid #dbdbdb;
    height: 2.5rem;
  }
  .form-requirement {
    padding-top: 7px;
    font-size: 12px;
    color: #585b66;
  }
}

#detail-disabled {
  background: #868383;
}

.name-wrapper {
  display: flex;
  gap: 1.563rem;
  justify-content: space-between;
  input {
    min-width: 70px;
  }
}
