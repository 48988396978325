.find-page-wrapper {
  margin: 4.688rem auto;
  width: 80%;
  max-width: 864px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: #ffffff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
}

.input-form-container {
  margin: 1.563rem auto;
  width: 95%;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.input-form-item {
  padding: 1.563rem 1.875rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.find-page-wrapper .input-title {
  background-color: #ffffff;
  color: #3a3a3a;
}

.input-button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 1.875rem;
}

.input-button {
  background: #171e4f;
  color: #ffffff;
  padding: 0.625rem 1.25rem;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  font-weight: 700;
  border: none;
  &:hover {
    cursor: pointer;
    color: #ff5722;
  }
}
