.footer {
  display: flex;
  justify-content: center;
  position: sticky;
  width: 100%;
  height: 6rem;
  bottom: -6rem;
  color: white;
}

.footer-content {
  max-width: 1080px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 0.6rem;
}

.footer-content-left {
  margin: 1.5rem 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-content-right {
  margin: 1.5rem 0.5rem;
  display: flex;
  justify-content: flex-end;
}

.fb-logo,
.ig-logo {
  margin: 0.5rem;
  height: 2rem;
  width: auto;
}
